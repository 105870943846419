import React from "react";

import { useTranslation } from "react-i18next";

import Marquee from "react-fast-marquee";

import biglogo from '../img/Home/biglogo.png'
import main from '../img/Home/main.png'
import check from '../img/Home/check.png'
import ExchangeSystem from "./ExchangeSystem";
import FAQ from "./FAQ";
import Reviews from "./Reviews";

function Home({ exchangeSystemRef }) {
    
    const { t } = useTranslation();

    return (
        <div className="bg-white">
            <div className="h-[45px] w-full bg-[#2F71FF] flex items-center">
                <Marquee autoFill>
                    <p className="text-[14px] xl:text-[20px] roboto letterspacing2 text-white font-[600] mx-[10px]">{t('home.1')}</p>
                    <svg className="mx-[10px]" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M13 0L13.6577 5.72619C14.0446 9.20833 16.7917 11.9554 20.2738 12.3423L26 13L20.2738 13.6577C16.7917 14.0446 14.0446 16.7917 13.6577 20.2738L13 26L12.3423 20.2738C11.9554 16.7917 9.20833 14.0446 5.72619 13.6577L0 13L5.72619 12.3423C9.20833 11.9554 11.9554 9.20833 12.3423 5.72619L13 0Z" fill="white"/>
                    </svg>
                    <p className="mx-[10px] text-[14px] xl:text-[20px] roboto letterspacing2 text-white">{t('home.2')}</p>
                    <svg className="mx-[10px]" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path d="M13 0L13.6577 5.72619C14.0446 9.20833 16.7917 11.9554 20.2738 12.3423L26 13L20.2738 13.6577C16.7917 14.0446 14.0446 16.7917 13.6577 20.2738L13 26L12.3423 20.2738C11.9554 16.7917 9.20833 14.0446 5.72619 13.6577L0 13L5.72619 12.3423C9.20833 11.9554 11.9554 9.20833 12.3423 5.72619L13 0Z" fill="white"/>
                    </svg>
                </Marquee>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between relative pb-[100px] xl:pb-[210px]">
                <div className="mt-[50px] xl:mt-[180px]">
                    <img src={biglogo} alt="" />
                    <div className="flex gap-[20px] items-center mt-[65px]">
                        <img src={check} alt="" />
                        <p className="text-[#000000] roboto text-[14px] xl:text-[17px] font-[300]">{t('home.3')}</p>
                    </div>
                    <div className="flex gap-[20px] items-center mt-[20px]">
                        <img src={check} alt="" />
                        <p className="text-[#000000] roboto text-[14px] xl:text-[17px] font-[300]">{t('home.4')}</p>
                    </div>
                    <div className="flex gap-[20px] items-center mt-[20px]">
                        <img src={check} alt="" />
                        <p className="text-[#000000] roboto text-[14px] xl:text-[17px] font-[300]">{t('home.5')}</p>
                    </div>
                    <a href="#">
                        <div className="mt-[50px] flex items-center justify-center xl:w-[160px] p-[20px] h-[30px] xl:h-[67px] rounded-[10px] bg-[#2F71FF]">
                            <p className="roboto letterspacing1 font-[700] text-[14px] xl:text-[16px] text-white">{t('home.6')}</p>
                        </div>
                    </a>
                </div>
                <div className="hidden xl:block absolute right-0 top-[150px]">
                    <img src={main} alt="" />
                </div>
            </div>
            <div ref={exchangeSystemRef}>
                <ExchangeSystem />
            </div>
            <FAQ/>
            <Reviews/>
        </div>
    );
}

export default Home;