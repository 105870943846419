import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import r1 from '../img/Home/Reviews/1.png';
import r2 from '../img/Home/Reviews/2.png';
import r3 from '../img/Home/Reviews/3.png';
import r4 from '../img/Home/Reviews/4.png';
import r5 from '../img/Home/Reviews/5.png';
import r6 from '../img/Home/Reviews/6.png';
import r7 from '../img/Home/Reviews/7.png';
import r8 from '../img/Home/Reviews/8.png';
import r9 from '../img/Home/Reviews/9.png';
import r10 from '../img/Home/Reviews/10.png';

const faqData = [
    {
        rname1: 'review.2',
        review1: 'review.3',
        img: r1,
        rname2: 'review.4',
        review2: 'review.5',
        img2: r2,
    },
    {
        rname1: 'review.6',
        review1: 'review.7',
        img: r3,
        rname2: 'review.8',
        review2: 'review.9',
        img2: r4,
    },
    {
        rname1: 'review.10',
        review1: 'review.11',
        img: r5,
        rname2: 'review.12',
        review2: 'review.13',
        img2: r6,
    },
    {
        rname1: 'review.14',
        review1: 'review.15',
        img: r7,
        rname2: 'review.16',
        review2: 'review.17',
        img2: r8,
    },
    {
        rname1: 'review.18',
        review1: 'review.19',
        img: r9,
        rname2: 'review.20',
        review2: 'review.21',
        img2: r10,
    },
];

const generateOrderedDateTime = (count) => {
    const dateTimes = [];
    const now = new Date();
    
    let currentTime = new Date(now);
    
    for (let i = 0; i < count; i++) {
        const hours = currentTime.getHours().toString().padStart(2, '0');
        const minutes = currentTime.getMinutes().toString().padStart(2, '0');
        const date = currentTime.toLocaleDateString();

        dateTimes.push({ date, time: `${hours}:${minutes}` });

        const randomMinutes = Math.floor(Math.random() * (100 - 20 + 1)) + 20;
        currentTime.setMinutes(currentTime.getMinutes() - randomMinutes);
    }

    return dateTimes;
};

function Reviews() {
    const { t } = useTranslation();

    const dateTimes = generateOrderedDateTime(faqData.length * 2);

    const [swiper, setSwiper] = useState({});

    return (
        <div className='bg-white pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col pb-[80px] xl:pb-[160px]'>
            <div className='flex flex-row justify-between items-center xl:items-end'>
                <p className='text-[#2F71FF] furore text-[20px] xl:text-[90px]'>{t('review.1')}</p>
                <div className='flex gap-[20px]'>
                    <svg onClick={() => swiper.slidePrev()} className='cursor-pointer scale-50 xl:scale-100' xmlns="http://www.w3.org/2000/svg" width="47" height="45" viewBox="0 0 47 45" fill="none">
                        <path d="M1 22.1143L20.2857 2.82857M1 22.1143L20.2857 41.4M1 22.1143L46 22.1143" stroke="#2F71FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <svg onClick={() => swiper.slideNext()} className='rotate-180 cursor-pointer scale-50 xl:scale-100' xmlns="http://www.w3.org/2000/svg" width="47" height="45" viewBox="0 0 47 45" fill="none">
                        <path d="M1 22.1143L20.2857 2.82857M1 22.1143L20.2857 41.4M1 22.1143L46 22.1143" stroke="#2F71FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
            
            <div>
                <Swiper
                    spaceBetween={20}
                    breakpoints={{
                        640: { slidesPerView: 1 },
                        768: { slidesPerView: 2 },
                        1024: { slidesPerView: 3 },
                    }}
                    className='mt-[20px] xl:mt-[50px]'
                    loop={true}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                >
                    {faqData.map((sw, index) => (
                        <SwiperSlide key={index}>
                            <div>
                                <div className='flex-grow flex-col flex justify-between h-[240px] xl:h-[283px] bg-[#EAEAEA] rounded-[10px] p-[20px]'> 
                                    <div className='flex items-center gap-[30px]'>
                                        <img src={sw.img} alt="" />
                                        <p className='roboto font-[500] text-[16px]'>{t(sw.rname1)}</p>
                                    </div>
                                    <p className='xl:text-[16px] text-[14px] roboto font-[300] xl:max-w-[300px]'>{t(sw.review1)}</p>
                                    <div className='flex flex-col items-end'>
                                        <p className='roboto text-[16px] font-[300]'>{dateTimes[index * 2].date}</p>
                                        <p className='roboto text-[16px] font-[300]'>{dateTimes[index * 2].time}</p>
                                    </div>
                                </div>
                                <div className='flex-grow flex-col flex justify-between h-[240px] xl:h-[283px] bg-[#EAEAEA] rounded-[10px] p-[20px] mt-[20px]'> 
                                    <div className='flex items-center gap-[30px]'>
                                        <img src={sw.img2} alt="" />
                                        <p className='roboto font-[500] text-[16px]'>{t(sw.rname2)}</p>
                                    </div>
                                    <p className='xl:text-[16px] text-[14px] roboto font-[300] xl:max-w-[300px]'>{t(sw.review2)}</p>
                                    <div className='flex flex-col items-end'>
                                        <p className='roboto text-[16px] font-[300]'>{dateTimes[index * 2 + 1].date}</p>
                                        <p className='roboto text-[16px] font-[300]'>{dateTimes[index * 2 + 1].time}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>   
        </div>
    );
}

export default Reviews;
