import React from "react";

import { useTranslation } from "react-i18next";

import logo from '../img/logo.svg'

function Footer() {
    
    const { t } = useTranslation();

    return (
        <div className="bg-white pb-[40px] xl:pb-[80px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row items-start gap-[20px] justify-between">
                <img src={logo} alt="" />
                <div>
                    <p className="roboto font-[300] text-[14px]">Al Sufouh 2, Dubai, Al Marsa street, 7/3a</p>
                </div>
                <div>
                    <p className="roboto font-[300] text-[14px] max-w-[591px]">{t('footer.1')}</p>
                    <p className="roboto font-[300] text-[14px] max-w-[591px] mt-[15px]">ExchangeXpress. All rights reserved.2024</p>
                </div>
            </div>
        </div>
       
    );
}

export default Footer;