import React, { useState } from 'react';

import { useTranslation, Trans } from "react-i18next";

import www from '../img/Home/www.png'

const faqData = [
    {
        question: 'faq.2',
        answer: 'faq.3'
    },
    {
        question: 'faq.4',
        answer: 'faq.5'
    },
    {
        question: 'faq.6',
        answer: 'faq.7'
    },
    {
        question: 'faq.8',
        answer: 'faq.9'
    },
    {
        question: 'faq.10',
        answer: 'faq.11'
    },
    {
        question: 'faq.12',
        answer: 'faq.13'
    },
    {
        question: 'faq.14',
        answer: 'faq.15'
    },
    {
        question: 'faq.16',
        answer: 'faq.17'
    },
]

function FAQ() {
    
    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className='bg-white pt-[80px] xl:pt-[160px] max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col'>
            <div className="flex flex-col xl:flex-row relative justify-between">
                <div className=''>
                    <p className='text-[#2F71FF] furore text-[20px] xl:text-[90px]'>{t('faq.1')}</p>
                </div>
                <div className='xl:w-2/3'>
                    <div className="flex justify-between flex-col mt-[15px]">
                        {faqData.map((faq, index) => (
                            <div key={index}>
                                <div className={`flex justify-between mt-[20px] h-[54px] transition-all duration-300 ${openIndex === index ? 'h-auto' : ''}`}>
                                    <div className='mx-[25px]'>
                                        <p className='mt-[15px] manrope text-[14px] xl:text-[16px] text-[#000] font-[600] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                        {openIndex === index && (
                                        <p className='text-black roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px]'><Trans>{t(faq.answer)}</Trans></p>
                                        )}
                                    </div>
                                    <div className='cursor-pointer mx-[25px] mt-[7px]' onClick={() => toggleText(index)}>
                                        <svg className={`${openIndex === index ? 'mt-[10px] transition-transform rotate-[135deg]' : 'mt-[10px] transition-transform rotate-45'}`} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                            <path d="M17.5702 1.07107V15.2132M17.5702 1.07107H3.42808M17.5702 1.07107L1.07106 17.5702" stroke="#2F71FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className='h-px bg-black mt-[10px]'></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;