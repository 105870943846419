import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from "i18next";
import Cookies from "js-cookie";
import logo from "../img/logo.svg";

function Header({ scrollToExchangeSystem }) {

    const { t } = useTranslation();

    const languages = [
        { value: 'ru', label: 'RU' },
        { value: 'en', label: 'EN' },
        { value: 'de', label: 'DE' },
        { value: 'ro', label: 'RO' },
    ]

    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'ru');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
            setCurrentLang(storedLang);
            i18n.changeLanguage(storedLang);
        }
        document.body.setAttribute('dir', storedLang === 'ar' ? 'rtl' : 'ltr');
    }, [storedLang, currentLang]);

    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isModalOpen]);

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };

    const openModal = () => {
        setIsModalOpen(true);
        setSuccessMessage('');
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [formData, setFormData] = useState({
        indnumber: '',
        name: '',
        phone: '',
        req: '',
    });

    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        const { indnumber, name, phone, req } = formData;
        
        // Проверка на заполнение полей
        if (!indnumber || !name || !phone || !req) {
            setError('header.modal.13');
            return;
        }

        try {
            const response = await axios.post('https://api.exchange-xpress.com/api/exchange/sendContacts', {
                indnumber,
                name,
                phone,
                req
            });
            console.log('header.modal.10', response.data);
            setFormData({
                indnumber: '',
                name: '',
                phone: '',
                req: ''
            });
            setError('');
            setSuccessMessage('header.modal.12');
            setTimeout(() => {
                closeModal(); 
            }, 5000);
        } catch (error) {
            console.error('', error);
            setError('header.modal.11');
        }
    };

    return (
        <div className="bg-white relative z-[999]">
            <div className="max-w-[1280px] h-[60px] xl:h-[100px] justify-between mx-auto flex items-center px-[20px]">
                <div>
                    <img src={logo} alt="" />
                </div>
                <div className="flex sm:gap-[60px] items-center">
                    <Dropdown
                        options={languages}
                        placeholder="RU"
                        onChange={handleChangeLang}
                        value={languages.find(lang => lang.value === currentLang)}
                    />
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="38" 
                        height="38" 
                        viewBox="0 0 38 38" 
                        fill="none" 
                        onClick={openModal}
                        className="cursor-pointer"
                    >
                        <path d="M32.7899 5.78663C32.7899 5.78663 35.8655 4.58726 35.6082 7.4998C35.5235 8.69917 34.7548 12.8974 34.1563 17.4376L32.1059 30.888C32.1059 30.888 31.9349 32.8585 30.3967 33.2013C28.8593 33.5433 26.5524 32.0019 26.1249 31.6591C25.7829 31.4018 19.7171 27.5464 17.5812 25.6622C16.9827 25.1476 16.2987 24.12 17.6667 22.9207L26.6371 14.3533C27.6623 13.3257 28.6875 10.9269 24.4157 13.8395L12.4536 21.9778C12.4536 21.9778 11.0864 22.8352 8.52374 22.0641L2.9694 20.3501C2.9694 20.3501 0.918988 19.0653 4.42211 17.7804C12.9666 13.754 23.4759 9.64205 32.7883 5.78663H32.7899Z" fill="black"/>
                    </svg>
                    <a onClick={scrollToExchangeSystem} className="hidden sm:block cursor-pointer">
                        <div className="flex items-center justify-center xl:w-[139px] p-[20px] h-[30px] xl:h-[42px] rounded-[10px] bg-[#2F71FF]">
                            <p className="roboto letterspacing1 font-[700] text-[14px] xl:text-[16px] text-white">{t('header.1')}</p>
                        </div>
                    </a>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white w-[80%] h-[90%] xl:h-[90%] rounded-[20px] flex flex-col relative">
                        <p onClick={closeModal} className="absolute right-[10px] top-[10px] md:right-[20px] md:top-[20px] text-[#2F71FF] text-[30px] cursor-pointer">⨉</p>
                        <p className="mt-[60px] xl:mt-[100px] text-[#2F71FF] text-[20px] xl:text-[48px] furore mx-[20px] md:mx-auto text-center">{t('header.modal.1')}</p>
                        <p className="mt-[15px] mx-[20px] md:mx-auto text-center roboto text-[14px] xl:text-[16px]">{t('header.modal.2')}</p>
                        <div className="md:mx-auto mt-[20px] xl:text-[30px] w-full md:w-[382px] mx-[20px] flex flex-col">
                            <div>
                                <p className="roboto text-[14px] xl:text-[16px] font-[500] xl:ml-[30px]">{t('header.modal.3')}</p>
                                <input 
                                    type="text" 
                                    name="indnumber"
                                    value={formData.indnumber}
                                    onChange={handleInputChange}
                                    className="roboto text-[16px] text-black md:w-[382px] w-[calc(100%-40px)] h-[54px] rounded-[40px] border border-black px-[30px] mt-[5px]" 
                                    placeholder={t('header.modal.4')} 
                                />
                            </div>
                            <div className="mt-[15px] xl:mt-[25px]">
                                <p className="roboto text-[14px] xl:text-[16px] font-[500] xl:ml-[30px]">{t('header.modal.5')}</p>
                                <input 
                                    type="text" 
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="roboto text-[16px] text-black md:w-[382px] w-[calc(100%-40px)] h-[54px] rounded-[40px] border border-black px-[30px] mt-[5px]" 
                                    placeholder={t('header.modal.6')} 
                                />
                            </div>
                            <div className="mt-[15px] xl:mt-[25px]">
                                <p className="roboto text-[14px] xl:text-[16px] font-[500] xl:ml-[30px]">{t('header.modal.7')}</p>
                                <input 
                                    type="text" 
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="roboto text-[16px] text-black md:w-[382px] w-[calc(100%-40px)] h-[54px] rounded-[40px] border border-black px-[30px] mt-[5px]" 
                                    placeholder={t('header.modal.8')} 
                                />
                            </div>
                            <div className="mt-[15px] xl:mt-[25px]">
                                <p className="roboto text-[14px] xl:text-[16px] font-[500] xl:ml-[30px]">{t('header.modal.dop.1')}</p>
                                <input 
                                    type="text" 
                                    name="req"
                                    value={formData.req}
                                    onChange={handleInputChange}
                                    className="roboto text-[16px] text-black md:w-[382px] w-[calc(100%-40px)] h-[54px] rounded-[40px] border border-black px-[30px] mt-[5px]" 
                                    placeholder={t('header.modal.dop.1')} 
                                />
                            </div>
                            {error && <p className="text-red-500 text-center mt-4 text-[16px]">{t(error)}</p>}
                            {successMessage && <p className="text-green-500 text-center mt-4 text-[16px]">{t(successMessage)}</p>} {/* Success message */}
                            <div 
                                className="buttontelega mt-[20px] cursor-pointer mx-auto mr-[40px] xl:mr-0 xl:ml-auto" 
                                onClick={handleSubmit}
                            >
                                <p className="text-white roboto text-[16px] font-[600]">{t('header.modal.9')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Header;
